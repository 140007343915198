




















































































import { Vue } from "vue-property-decorator";

export default Vue.extend({
  name: "Signup" as string,
  data() {
    return {
      form: {
        firstname: "",
        lastname: "",
        username: "",
        phone: "",
        city: "",
        state: "",
        zipcode: ""
      },
      formfields: [
        {
          field: "firstname",
          display: "First name",
          maxlength: "50",
          required: true,
          width: 3
        },
        {
          field: "lastname",
          display: "Last name",
          maxlength: "50",
          required: true,
          width: 3
        },
        {
          field: "city",
          display: "City",
          maxlength: "50",
          required: true,
          width: 2
        },
        {
          field: "state",
          display: "State",
          maxlength: "2",
          note: "2-letter code",
          pattern:
            "^([Aa][LKSZRAEPlkszraep]|[Cc][AOTaot]|[Dd][ECec]|[Ff][LMlm]|[Gg][AUau]|[Hh][Ii]|[Ii][ADLNadln]|[Kk][SYsy]|[Ll][Aa]|[Mm][ADEHINOPSTadehinopst]|[Nn][CDEHJMVYcdehjmvy]|[Oo][HKRhkr]|[Pp][ARWarw]|[Rr][Ii]|[Ss][CDcd]|[Tt][NXnx]|[Uu][Tt]|[Vv][AITait]|[Ww][AIVYaivy])$",
          required: true,
          width: 2,
          classes: "uppercase"
        },
        {
          field: "zipcode",
          display: "Zipcode",
          maxlength: "5",
          pattern: `^\\d{5}$`,
          note: "5 digits",
          required: false,
          width: 2
        },
        {
          field: "username",
          display: "Username",
          maxlength: "20",
          pattern: "^\(\w|.){8,20}$",
          note: "8-20 letters, digits, ., or _",
          required: true,
          width: 3
        },
        {
          field: "phone",
          display: "Phone",
          maxlength: "10",
          pattern: "^\\d{10}$",
          note: "10 digits (used for account verification only)",
          required: true,
          prefix: "+1",
          width: 3,
          classes: "font-mono"
        }
      ],
      error: ""
    };
  },
  methods: {
    register() {
      this.$store
        .dispatch("register", this.form)
        .then(resp => {
          console.log(resp);
          if (resp.status == 200) {
            this.$router.push({
              name: "Login",
              params: { phone: this.form.phone }
            });
            this.$router.push("/login");
          }
        })
        .catch(err => {
          this.error = err.response.data;
        });
    },
    fieldname(field: string) {
      return field
        .split(" ")
        .join("")
        .toLowerCase();
    }
  },
  beforeCreate() {
    if (this.$store.getters.isLoggedIn) {
      this.$router.push("/");
    }
  }
});
