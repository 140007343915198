<template>
  <label for="toogleA" class="block">
    <!-- toggle (modified version of https://codepen.io/lhermann/pen/EBGZRZ) -->
    <div class="relative">
      <!-- input -->
      <input @input="handleInput" id="toogleA" type="checkbox" class="hidden" />
      <!-- line -->
      <div
        class="toggle__line w-10 h-5 bg-gray-400 rounded-full shadow-inner"
      ></div>
      <!-- dot -->
      <div
        class="toggle__dot absolute top-0.5 left-0.5 w-4 h-4 cursor-pointer bg-white rounded-full shadow inset-y-0"
      ></div>
    </div>
    <!-- label -->
  </label>
</template>

<script>
export default {
  name: "Toggle",
  prop: ["value"],
  data() {
    return {
      content: this.value
    };
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e.target.checked);
    }
  }
};
</script>

<style scoped>
.toggle__dot {
  transition: all 0.1s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(122%);
}

input:checked ~ .toggle__line {
  background-color: #48bb78;
}
</style>
