import Vue from "vue";
import Vuex from "vuex";
import { Provision } from "@/types";

Vue.use(Vuex);
const authdomain = "https://auth.populegis.org";

export default new Vuex.Store({
  state: {
    location: 0,
    provision: undefined,
    notification: {},
    authstatus: "",
    token: "",
    user: { username: "", email: "", role: "" },
    exp: 0,
    prev: "/"
  },
  actions: {
    setlocation({ commit }, info: { loc: number }) {
      const { loc } = info;
      commit("SET_LOC", loc);
    },
    setprovision({ commit }, info: { provision: Provision }) {
      const { provision } = info;
      commit("SET_PROVISION", provision);
    },
    setnotification({ commit }, info: { notification: any }) {
      const { notification } = info;
      commit("SET_NOTIFICATION", notification);
    },
    setPrev({ commit }, path: string) {
      commit("SET_PREV", path);
    },
    register({ commit }, form) {
      return new Promise((resolve, reject) => {
        commit("AUTH_REQUEST");
        Vue.axios
          .post(`${authdomain}/register`, form)
          .then(resp => {
            commit("AUTH_VALIDATION");
            resolve(resp);
          })
          .catch(err => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    sendSms({ commit }, form) {
      return new Promise((resolve, reject) => {
        commit("AUTH_REQUEST");
        Vue.axios
          .post(`${authdomain}/send_sms`, form)
          .then(resp => {
            commit("AUTH_VALIDATION");
            resolve(resp);
          })
          .catch(err => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    login({ commit }, form) {
      return new Promise((resolve, reject) => {
        commit("AUTH_REQUEST");
        Vue.axios
          .post(`${authdomain}/login`, form)
          .then(resp => {
            const token = resp.data.access_token;
            const user = resp.data.user;
            const exp = resp.data.exp;
            console.log(user);
            commit("AUTH_SUCCESS", token);
            commit("AUTH_USER", user);
            commit("AUTH_EXP", exp);
            resolve(resp);
          })
          .catch(err => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    refreshToken({ commit }) {
      return new Promise((resolve, reject) => {
        commit("AUTH_REQUEST");
        Vue.axios
          .post(`${authdomain}/refresh_token`)
          .then(resp => {
            if (resp.data.ok) {
              const token = resp.data.access_token;
              const user = resp.data.user;
              const exp = resp.data.exp;
              commit("AUTH_SUCCESS", token);
              commit("AUTH_USER", user);
              commit("AUTH_EXP", exp);
            } else {
              commit("LOGOUT");
            }
            resolve(resp);
          })
          .catch(err => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("AUTH_REQUEST");
        Vue.axios
          .post(`${authdomain}/logout`)
          .then(resp => {
            if (resp.data.ok) {
              commit("LOGOUT");
            }
            resolve(resp);
          })
          .catch(err => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    getToken({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        if (Date.now() / 1000 < state.exp) {
          console.log("Already have token");
          resolve(state.token);
        } else {
          console.log("Refreshing token");
          dispatch("refreshToken")
            .then(resp => {
              if (resp.data.ok) {
                resolve(resp.data.access_token);
              }
            })
            .catch(err => {
              commit("AUTH_ERROR");
              reject(err);
            });
        }
      });
    },
    sendVerificationEmail(_, form) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(`${authdomain}/sendverificationemail`, form)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },
  mutations: {
    SET_LOC(state, loc) {
      Vue.set(state, "location", loc);
    },
    SET_PROVISION(state, provision) {
      Vue.set(state, "provision", provision);
    },
    SET_NOTIFICATION(state, notification) {
      Vue.set(state, "notification", notification);
    },
    SET_PREV(state, path) {
      Vue.set(state, "prev", path);
    },
    AUTH_VALIDATION(state) {
      Vue.set(state, "authstatus", "validating");
    },
    AUTH_REQUEST(state) {
      Vue.set(state, "authstatus", "loading");
    },
    AUTH_SUCCESS(state, token) {
      Vue.set(state, "authstatus", "success");
      Vue.set(state, "token", token);
    },
    AUTH_USER(state, user) {
      Vue.set(state, "user", user);
    },
    AUTH_ERROR(state) {
      Vue.set(state, "authstatus", "error");
    },
    AUTH_EXP(state, exp) {
      Vue.set(state, "exp", exp);
    },
    LOGOUT(state) {
      Vue.set(state, "authstatus", "");
      Vue.set(state, "token", "");
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authLoading: state => state.authstatus == "loading",
    authStatus: state => state.authstatus,
    verified: state => !!state.token && state.user.role == "user"
  }
});
