




























































































import { Vue } from "vue-property-decorator";

export default Vue.extend({
  name: "TocItem" as string,
  props: ["provision", "slug", "expanded"],
  data() {
    return {
      notoc: ["whereas", "toc"],
      onlywithheader: [
        "paragraph",
        "subparagraph",
        "subsection",
        "appropriations-small",
      ],
      onlychildren: ["legis-body", "quoted-block"]
    };
  },
  computed: {
    selectedlocation() {
      return this.$store["state"].location;
    },
    selected() {
      return this.provision["location"] == this.$store["state"].location;
    },
  },
  methods: {
    toLoc(provision) {
      this.$store.dispatch("setlocation", { loc: provision.location });
    },
    toDisplay(provision) {
      if (this.$data.notoc.includes(provision.provision_type)) {
        return false;
      } else if (this.$data.onlywithheader.includes(provision.provision_type) &&
            provision.header == '') {
        return false;
      }
      return true;
    }
  },
});
