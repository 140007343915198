

































import { Vue } from "vue-property-decorator";
import TopBar from "./components/TopBar.vue";

Vue.component("topbar", TopBar);

export default Vue.extend({
  name: "App" as string,
  data() {
    return {
      displaynotification: false,
      color: "",
      message: ""
    };
  },
  computed: {
    notification() {
      return this.$store.state.notification;
    }
  },
  watch: {
    notification(newVal, oldVal) {
      this.displaynotification = true;
      this.color = "error" in this.notification ? "red" : "gray";
      this.message =
        "error" in this.notification
          ? "Something went wrong."
          : this.notification.alert;
    }
  },
  created() {
    document.body.classList.add("bg-coolgray-50");
  }
});
