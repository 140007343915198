






































import { Component, Prop, Vue } from "vue-property-decorator";
import { Provision } from "@/types";
import TextComponent from "@/components/TextComponent.vue";

Vue.component("textcontent", TextComponent);

@Component
export default class SubparagraphComponent extends Vue {
  @Prop() provision!: Provision;
  @Prop() selected!: boolean;
}
