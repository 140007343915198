import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import Content from "@/views/Content.vue";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Login from "@/views/Login.vue";
import Signup from "@/views/Signup.vue";
import Profile from "@/views/Profile.vue";
import Settings from "@/views/Settings.vue";
import store from "@/store";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import axios from "axios";
import VueAxios from "vue-axios";
import "./assets/main.scss";

axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios);

const httpLink = new HttpLink({
  uri: "https://api.populegis.org/v1/graphql"
});

const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  connectToDevTools: true
});

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        title: "Popular Legislation",
        metaTags: [
          {
            name: 'description',
            content: 'Popular Legislation (or PopuLegis) is a policy search engine, opinion aggregator, and discussion forum.'
          },
          {
            name: 'keywords',
            content: 'search engine, legislation, policy search engine, opinion aggregator, policy forum, legislation forum'
          }
        ]
      }
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      props: true,
      meta: {
        title: "Log in to PopuLegis"
      }
    },
    {
      path: "/signup",
      name: "Signup",
      component: Signup,
      meta: {
        title: "Sign up for PopuLegis"
      }
    },
    {
      path: "/about",
      name: "About",
      component: About,
      meta: {
        title: "About - PopuLegis"
      }
    },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      meta: {
        title: "Settings - PopuLegis"
      }
    },
    {
      path: "/404",
      name: "404",
      component: {
        template: `
          <div class="w-full h-full flex justify-center items-center">
            <div class="text-5xl font-mono text-coolgray-700">
              Page not found.
            </div>
          </div>
        `
      }
    },
    {
      path: "/:tab",
      name: "Home-Tab",
      component: Home,
      props: true,
      meta: {
        title: "PopuLegis"
      }
    },
    {
      path: "/profile/:username",
      name: "Profile",
      component: Profile,
      props: true,
      meta: {
        title: "Profile - PopuLegis"
      }
    },
    {
      path: "/bill/:slug",
      name: "Content",
      component: Content,
      props: true,
      meta: {
        title: "Bill - PopuLegis"
      }
    },
    {
      path: "/bill/:slug/:loc",
      name: "Content-Loc",
      component: Content,
      props: true,
      meta: {
        title: "Bill - PopuLegis"
      }
    }
  ]
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode?.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

Vue.config.productionTip = false;

new Vue({
  router,
  apolloProvider,
  render: h => h(App),
  store
}).$mount("#app");
