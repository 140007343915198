

















































































import { Vue } from "vue-property-decorator";
import {
  CREATE_VOTE_MUTATION,
  DELETE_VOTE_MUTATION
} from "@/constants/graphql";
import { metricFormat } from "@/utils";

export default Vue.extend({
  name: "ReactionsComponent" as string,
  props: [
    "comment",
    "legislation",
    "provision",
    "value",
    "initialvote",
    "initialupvotes",
    "initialdownvotes"
  ],
  data() {
    return {
      modvote: "",
      upvotesCount: this.initialupvotes,
      downvotesCount: this.initialdownvotes,
      content: this.value
    };
  },
  computed: {
    currentvote(): string {
      return this.modvote
        ? this.modvote
        : this.initialvote
        ? this.initialvote
        : "neutral";
    },
    verified(): boolean {
      return this.$store.getters.authLoading
        ? false
        : this.$store.getters.verified;
    }
  },
  watch: {
    currentvote(newVal) {
      this.$emit("input", newVal);
    }
  },
  methods: {
    async submitVote(option: string) {
      if (this.currentvote != "neutral" && option != this.currentvote) {
        return;
      } else if (!this.verified) {
        const result = option == this.currentvote ? "neutral" : option;
        this.$store.dispatch("setnotification", {
          notification: { alert: "Log in or sign up to save your votes." }
        });
        this.modvote = result;
        return;
      }
      let mutation, result, change;
      if (option == this.currentvote) {
        mutation = DELETE_VOTE_MUTATION;
        result = "neutral";
        change = -1;
      } else {
        mutation = CREATE_VOTE_MUTATION;
        result = option;
        change = 1;
      }
      const old = this.currentvote;
      const token = await this.$store.dispatch("getToken");
      //console.log(token);
      const userid = this.$store.state.user
        ? this.$store.state.user.id
        : null;
      //console.log(userid);
      this.$apollo
        .mutate({
          // Query
          mutation: mutation,
          // Parameters
          variables: {
            userid: userid,
            commentid: this.comment?.comment_id,
            packageid: this.legislation
              ? this.legislation.packageid
              : this.provision?.packageid,
            voteoption: option,
            provisionTag: this.provision ? this.provision.provision_tag : "0"
          },
          context: {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        })
        .then(response => {
          // Result
          console.log(response);
          this.modvote = result;
          if (option == "up") {
            this.upvotesCount += change;
          } else if (option == "down") {
            this.downvotesCount += change;
          }
        })
        .catch(e => {
          this.modvote = old;
          console.error(e);
          this.$store.dispatch("setnotification", {
            notification: { error: e }
          });
        });
    }
  },
  filters: {
    metricFormat
  },
  mounted() {
    this.$emit("input", this.currentvote);
  }
});
