<template>
  <div class="flex justify-between items-center text-xs py-1 text-gray-500">
    <button
      :disabled="value == 1"
      @click="handleInput(value - 1)"
      class="flex space-x-2 items-center disabled:opacity-50"
      :class="`${value == 1 ? 'cursor-default' : ''}`"
    >
      <span class="material-icons-outlined text-base">west</span>
      <span class="sm:hidden md:inline">Previous</span>
    </button>
    <div class="flex space-x-2">
      <span>Page</span>
      <span>{{ value }}</span>
    </div>
    <button
      :disabled="nextpage == false"
      @click="handleInput(value + 1)"
      class="flex space-x-2 items-center disabled:opacity-50"
      :class="`${nextpage ? '' : 'cursor-default'}`"
    >
      <span class="sm:hidden md:inline">Next</span>
      <span class="material-icons-outlined text-base">east</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["value", "nextpage"],
  data() {
    return {
      content: this.value
    };
  },
  methods: {
    handleInput(v) {
      this.$emit("input", v);
    }
  }
};
</script>
