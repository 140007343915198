import prettyMilliseconds from "pretty-ms";
import { Provision, Bill } from "@/types";

export const versions = {
  ath: {
    full: "Agreed to by House",
    brief: "Passed House",
    chamber: "House",
    status: "Passed"
  },
  ats: {
    full: "Agreed to by Senate",
    brief: "Passed Senate",
    chamber: "Senate",
    status: "Passed"
  },
  cps: {
    full: "Considered and Passed by Senate",
    brief: "Passed Senate",
    chamber: "Senate",
    status: "Passed"
  },
  eah: {
    full: "Engrossed Amendment as Agreed to by House",
    brief: "Passed House",
    chamber: "House",
    status: "Passed"
  },
  eas: {
    full: "Engrossed Amendment as Agreed to by Senate",
    brief: "Passed Senate",
    chamber: "Senate",
    status: "Passed"
  },
  eh: {
    full: "Engrossed as Agreed to or Passed by House",
    brief: "Passed House",
    chamber: "House",
    status: "Passed"
  },
  es: {
    full: "Engrossed as Agreed to or Passed by Senate",
    brief: "Passed Senate",
    chamber: "Senate",
    status: "Passed"
  },
  ih: {
    full: "Introduced in House",
    brief: "Introduced in House",
    chamber: "House",
    status: "Introduced"
  },
  is: {
    full: "Introduced in Senate",
    brief: "Introduced in Senate",
    chamber: "Senate",
    status: "Introduced"
  },
  pcs: {
    full: "Placed on Calendar in Senate",
    brief: "Introduced in Senate",
    chamber: "Senate",
    status: "Introduced"
  },
  rcs: {
    full: "Referred to Different or Additional Senate Committee",
    brief: "Introduced in Senate",
    chamber: "Senate",
    status: "Introduced"
  },
  rds: {
    full: "Received in Senate From House",
    brief: "Passed House",
    chamber: "House",
    status: "Passed"
  },
  rfs: {
    full: "Referred to Senate Committee after being Received from House",
    brief: "Passed House",
    chamber: "House",
    status: "Passed"
  },
  rh: {
    full: "Reported in House",
    brief: "Introduced in House",
    chamber: "House",
    status: "Introduced"
  },
  rs: {
    full: "Reported in Senate",
    brief: "Introduced in Senate",
    chamber: "Senate",
    status: "Introduced"
  },
  enr: { full: "Enrolled Bill", brief: "Passed House and Senate" }
};

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export function timeFormat(s: string) {
  const del: number = new Date().getTime() - new Date(s + "Z").getTime();
  return del < 60000 ? "1m" : prettyMilliseconds(del, { compact: true });
}

export function cardTimeFormat(s: string) {
  const thisyear = new Date().getFullYear();
  const d = new Date(s + "T00:00:00.000");
  return `${month[d.getMonth()]} ${d.getDate()}${
    d.getFullYear() == thisyear ? "" : ", " + d.getFullYear()
  }`;
}

function round(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function metricFormat(m: number) {
  const res =
    m < 10 ** 3
      ? m
      : m < 10 ** 6
      ? round(m / 10 ** 3, 1)
      : round(m / 10 ** 6, 1);
  const suf = m < 10 ** 3 ? "" : m < 10 ** 6 ? "K" : "M";
  return `${res} ${suf}`;
}

export function printType(t: string) {
  const d = {
    hr: "H.R.",
    s: "S.",
    sres: "S.RES.",
    hjres: "H.J.RES.",
    sjres: "S.J.RES.",
    hres: "H.RES.",
    sconres: "S.CON.RES.",
    hconres: "H.CON.RES."
  };
  return d[t];
}

export function printClass(t: string) {
  const d = {
    hjres: "JOINT RESOLUTION",
    sjres: "JOINT RESOLUTION",
    sres: "RESOLUTION",
    hres: "RESOLUTION",
    hr: "A BILL",
    s: "A BILL",
    sconres: "CONCURRENT RESOLUTION",
    hconres: "CONCURRENT RESOLUTION"
  };
  return d[t];
}

export function billVersion(v: string) {
  const d = {
    ih: "Introduced in House",
    is: "Introduced in Senate",
    eh: "Passed House",
    es: "Passed Senate",
    rfs: "Passed House",
    rfh: "Passed Senate"
  };
  return d[v];
}

export function listtotree(list: Provision[]) {
  const map: { [key: number]: number } = {};
  let node: Provision;
  const roots: Provision[] = [];
  for (let i = 0; i < list.length; i += 1) {
    map[list[i].location] = i; // initialize the map
    list[i].children = []; // initialize the children

    node = list[i];
    if (map[node.parent_location] != undefined) {
      list[map[node.parent_location]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  //console.log(roots);
  return { location: 0, children: roots } as Provision;
}

export function uniquebills(bills): Bill[] {
  const ids: string[] = [];
  const ubills: Bill[] = [];
  for (const b of bills) {
    const bid = `${b.congress}${b.bill_type}${b.number}`;
    if (ids.includes(bid)) {
      continue;
    }
    ids.push(bid);
    ubills.push(b);
  }
  return ubills;
}

export function checkforvotes(root: Provision): boolean {
  if (root.votes?.length > 0) {
    return true;
  }
  for (const child of root.children) {
    if (checkforvotes(child)) {
      return true;
    }
  }
  return false;
}

export function OrdinalSuffix(i: number) {
  const j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}
