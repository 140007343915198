


















































































































































































import { Vue } from "vue-property-decorator";
import SectionContent from "@/components/SectionContent.vue";
import TextComponent from "@/components/TextComponent.vue";
import TableComponent from "@/components/TableComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import SubparagraphComponent from "@/components/SubparagraphComponent.vue";
import ListComponent from "@/components/ListComponent.vue";
import { VTooltip } from "v-tooltip";
import ReactionsComponent from "@/components/ReactionsComponent.vue";

Vue.directive("tooltip", VTooltip);
Vue.component("sect", SectionContent);
Vue.component("textcontent", TextComponent);
Vue.component("headercomponent", HeaderComponent);
Vue.component("subparagraph", SubparagraphComponent);
Vue.component("tblcmpnt", TableComponent);
Vue.component("list", ListComponent);
Vue.component("reactions", ReactionsComponent);

export default Vue.extend({
  name: "ProvisionComponent" as string,
  props: ["provision"],
  data() {
    return {
      hover: false,
      currentvote: "neutral",
      urlcopy: "Copy URL",
      noninteractive: ["legis-body", "quoted-block", "toc", "table"],
      nodisplay: [
        "toc-entry",
        "row",
        "entry",
        "bold",
        "list-item",
        "pagebreak"
      ],
      nocontent: ["legis-body", "quoted-block"],
      selectedcolor: "bg-yellow-100 bg-opacity-70"
    };
  },
  computed: {
    selected() {
      return this.provision.location == this.$store.state.location;
    }
  },
  methods: {
    visibilityChanged(isVisible: boolean, entry: any) {
      if (!isVisible) {
        return;
      }
      //console.log(this.provision.location, isVisible);
    },
    mouselog(event: Event) {
      this.hover = event.type == "mouseover";
    },
    pclicked(event: Event) {
      //console.log(this.provision.location, "clicked");
      this.$store.dispatch("setprovision", { provision: this.provision });
    },
    copyURL() {
      const slug = this.provision.packageid.split("-")[1];
      const url =
        window.location.host +
        "/bill/" +
        slug +
        "/" +
        this.provision.location.toString();
      const el = document.createElement("textarea");
      el.value = url;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.urlcopy = "URL copied ✅";
      // setTimeout(() => {this.urlcopy = 'Copy URL';}, 3000);
    }
  }
});
