




































































































































import { Vue } from "vue-property-decorator";
import { GET_USER_QUERY, UPDATE_USER } from "@/constants/graphql";
import { User } from "@/types";
import Toggle from "@/components/Toggle.vue";
import Loader from "@/components/Loader.vue";

Vue.component("toggle", Toggle);
Vue.component("loader", Loader);

export default Vue.extend({
  name: "Settings" as string,
  data() {
    return {
      user: {} as User,
      form: {
        firstname: "",
        lastname: "",
        zipcode: "",
        city: "",
        state: ""
      },
      updateloading: false,
      btext: "Save",
      homeFilter: null
    };
  },
  apollo: {
    user: {
      query: GET_USER_QUERY,
      variables(): {} {
        return {
          username: this.$store.state.user.username
        };
      },
      update: data => data.users[0],
      fetchPolicy: "no-cache"
    }
  },
  computed: {
    loading() {
      return this.$store.getters.authLoading || this.$apollo.loading;
    },
    modified() {
      for (const k in this.user) {
        if (k in this.form) {
          if (this.form[k] != this.user[k] && this.form[k] != "") {
            return true;
          }
        }
      }
      return false;
    }
  },
  methods: {
    async updateProfile() {
      if (this.loading) {
        return;
      }
      this.updateloading = true;
      const token = await this.$store.dispatch("getToken");
      this.$apollo
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            authid: this.user.username,
            lastname: this.form.lastname
              ? this.form.lastname
              : this.user.lastname,
            firstname: this.form.firstname
              ? this.form.firstname
              : this.user.firstname,
            zipcode: this.form.zipcode ? this.form.zipcode : this.user.zipcode,
            state: this.form.state ? this.form.state : this.user.state,
            city: this.form.city ? this.form.city : this.user.city
          },
          context: { headers: { Authorization: `Bearer ${token}` } }
        })
        .then(response => {
          this.updateloading = false;
          this.btext = "Saved ✅";
        })
        .catch(e => {
          console.error(e);
          this.$store.dispatch("setnotification", {
            notification: { error: e }
          });
        });
    }
  }
});
