








































































import { Vue } from "vue-property-decorator";
import { cardTimeFormat, printType, versions, metricFormat } from "@/utils";

export default Vue.extend({
  name: "BillCard" as string,
  props: ["bill", "context", "loading"],
  computed: {
    vinfo: function() {
      if (this.bill.version == "enr") {
        const d = {
          chamber: "President",
          status: "Presented",
          brief: "Passed House and Senate",
        };
        if (this.bill.status != {}) {
          for (const item of this.bill.status.actions.items) {
            if (item.text.indexOf("Became Public Law") >= 0) {
              d["status"] = "Became Law";
              break;
            }
          }
        }
        return d;
      }
      return versions[this.bill.version];
    },
    numStages() {
      return versions[this.bill.version].num_stages;
    },
    iconsmetrics() {
      return [
        ["thumb_up", this.bill.upvotes_count],
        ["thumb_down", this.bill.downvotes_count],
        ["comment", this.bill.comments_count],
        [
          "verified",
          this.bill.status != {} && this.bill.status.cosponsors?.items
            ? this.bill.status.cosponsors.items.length
            : 0,
        ],
      ];
    },
  },
  methods: {
    PrintType(t: string) {
      return printType(t);
    },
  },
  filters: {
    cardTimeFormat,
    metricFormat,
  },
});
