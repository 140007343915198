var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-grow text-coolgray-700 text-sm"},[_c('div',[(!!this.legislation)?_c('div',{staticClass:"my-4"},[_c('div',[_c('div',[_c('p',{staticClass:"text-xs"},[_vm._v(_vm._s(5 - this.udc)+" comments remaining today.")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.commentDraft),expression:"commentDraft"}],staticClass:"textarea w-full p-2 border focus:border-blue-300",attrs:{"id":"newcomment","placeholder":"Add a public comment","disabled":this.udc == 5},domProps:{"value":(_vm.commentDraft)},on:{"input":function($event){if($event.target.composing){ return; }_vm.commentDraft=$event.target.value}}})]),_c('div',{staticClass:"flex items-stretch justify-between mt-2"},[(this.provision != undefined)?_c('div',{staticClass:"rounded bg-blue-500 text-white text-sm py-1 px-2 flex items-center space-x-1"},[_c('span',{staticClass:"text-xs font-mono font-light"},[_vm._v(" "+_vm._s(this.provision.display_tag)+" ")]),_c('button',{staticClass:"rounded-full material-icons-outlined text-sm font-bold bg-gray-700 bg-opacity-50 hover:bg-opacity-60 px-1",on:{"click":_vm.resetloc}},[_vm._v(" close ")])]):_c('div'),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: this.verified
                ? ''
                : _vm.$store.getters.isLoggedIn
                ? 'Verify your account to comment'
                : 'Log in to comment'
            }),expression:"{\n              content: this.verified\n                ? ''\n                : $store.getters.isLoggedIn\n                ? 'Verify your account to comment'\n                : 'Log in to comment'\n            }"}],staticClass:"material-icons-outlined h-7",class:((_vm.commentDraft
                  ? 'text-blue-500 hover:text-blue-600 cursor-pointer'
                  : 'text-coolgray-500 cursor-default') + " " + (_vm.$store.getters.isLoggedIn
                  ? 'cursor-default'
                  : 'cursor-not-allowed')),attrs:{"disabled":!_vm.commentDraft || !_vm.$store.getters.isLoggedIn || !this.verified},on:{"click":_vm.submitComment}},[_vm._v(" send ")])])])]):_vm._e(),_c('div',{staticClass:"border-t py-4"},[_c('div',{staticClass:"w-full text-right block mt-1 mb-2 text-xs"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderby),expression:"orderby"}],staticClass:"rounded border py-1 px-1 focus:outline-none bg-coolgray-50",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.orderby=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"created_at"}},[_vm._v("Newest")]),_c('option',{attrs:{"value":"upvotes_count"}},[_vm._v("Top Rated")])])]),(_vm.$apollo.loading)?_c('loader'):_vm._l((this.comments),function(comment,index){return _c('div',{key:index,staticClass:"card is-shadowless has-background-light"},[(comment.deleted_at == null)?_c('div',{staticClass:"group text-sm px-0 py-2 relative"},[_c('div',{staticClass:"flex justify-between"},[_c('div',[_c('router-link',{staticClass:"font-bold text-blue-500 font-sans text-sm",attrs:{"to":{
                  name: 'Profile',
                  params: { username: comment.user.username }
                }}},[_vm._v(" "+_vm._s(comment.user.firstname + " " + comment.user.lastname)+" ")]),_vm._v(" · "+_vm._s(_vm._f("timeFormat")(comment.updated_at))+" ")],1),_c('div',[(!!comment.provision)?_c('span',{staticClass:"bg-blue-500 text-white text-xs rounded py-1 px-2"},[_c('a',{staticClass:"font-mono",on:{"click":function($event){return _vm.toLoc(comment.provision)}}},[_vm._v(" "+_vm._s(comment.provision.display_tag)+" ")])]):_vm._e()])]),_c('div',{staticClass:"leading-snug"},[_vm._v(" "+_vm._s(comment.comment)+" ")]),_c('div',{staticClass:"flex justify-between items-start group"},[_c('reactions',{attrs:{"comment":comment,"initialvote":comment.votes && comment.votes[0]
                  ? comment.votes[0].vote_option
                  : undefined,"initialupvotes":comment.upvotes_count,"initialdownvotes":comment.downvotes_count}})],1),(!_vm.$store.getters.authLoading)?_c('a',{staticClass:"absolute right-0 bottom-0 material-icons-outlined opacity-0 group-hover:opacity-100 button h-6 w-6 px-1 hover:bg-coolgray-200 rounded-full text-base",class:("" + (comment.user.username == _vm.$store.state.user.username
                  ? 'visible'
                  : 'hidden')),on:{"click":function($event){return _vm.deleteComment(comment)}}},[_vm._v(" delete_outline ")]):_vm._e()]):_c('div',{staticClass:"text-sm px-4 py-2 has-text-weight-medium has-text-grey"},[_c('i',[_vm._v("[This comment was deleted]")])])])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }