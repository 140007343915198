var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged),expression:"visibilityChanged"}],staticClass:"pt-0.5 pb-1",on:{"mouseover":function($event){$event.stopPropagation();return _vm.mouselog.apply(null, arguments)},"mouseout":_vm.mouselog}},[(!this.nodisplay.includes(this.provision.provision_type))?_c('div',{staticClass:"provisioncontainer flex relative rounded text-coolgray-900",class:("\n      " + (_vm.hover ? 'bg-yellow-50' : '') + "\n      " + (_vm.provision.provision_type == 'quoted-block'
          ? 'bg-coolgray-100 mr-1 bg-opacity-80'
          : '') + "\n      ")},[(!_vm.noninteractive.includes(this.provision.provision_type))?_c('div',{staticClass:"hidden md:flex flex-col bg-coolgray-200 justify-start items-center",class:((_vm.hover || this.currentvote != 'neutral' ? 'visible' : 'invisible') + "\n        " + (this.currentvote == 'up'
          ? 'bg-green-100'
          : this.currentvote == 'down'
          ? 'bg-red-100'
          : _vm.hover
          ? 'bg-coolgray-100'
          : ''))},[_c('reactions',{attrs:{"provision":_vm.provision,"initialupvotes":_vm.provision.upvotes_count,"initialdownvotes":_vm.provision.downvotes_count,"initialvote":_vm.provision.votes && _vm.provision.votes[0]
            ? _vm.provision.votes[0].vote_option
            : 'neutral'},model:{value:(_vm.currentvote),callback:function ($$v) {_vm.currentvote=$$v},expression:"currentvote"}})],1):_vm._e(),_c('div',{staticClass:"w-full ml-2 mt-1"},[_c('div',{staticClass:"pr-1",class:("" + (_vm.selected ? 'selectedprovision' : ''))},[(_vm.provision.provision_type == 'section')?_c('sect',{class:_vm.selected ? _vm.selectedcolor : '',attrs:{"provision":_vm.provision}}):(
            [
              'subsection',
              'paragraph',
              'subparagraph',
              'continuation-text',
              'clause',
              'subclause',
              'whereas',
              'preamble',
              'resolution-body',
              'proviso',
              'constitution-article',
              'committee-appointment-paragraph',
              'item',
              'subitem',
              'quoted-block-continuation-text'
            ].includes(_vm.provision.provision_type)
          )?_c('subparagraph',{class:_vm.selected ? _vm.selectedcolor : '',attrs:{"provision":_vm.provision}}):(_vm.provision.provision_type == 'toc')?_c('div',{staticClass:"bg-coolgray-100 w-full rounded font-medium italic text-sm text-gray-700"},[_c('div',{staticClass:"p-2"},[_vm._v(" The table of contents is available in the side panel. ")])]):(
            [
              'appropriations-major',
              'appropriations-intermediate',
              'appropriations-small',
              'division',
              'title',
              'subtitle',
              'chapter',
              'subchapter',
              'part',
              'subpart'
            ].includes(_vm.provision.provision_type)
          )?_c('headercomponent',{class:_vm.selected ? _vm.selectedcolor : '',attrs:{"provision":_vm.provision}}):(_vm.provision.provision_type == 'table')?_c('tblcmpnt',{class:_vm.selected ? _vm.selectedcolor : '',attrs:{"provision":_vm.provision}}):(_vm.provision.provision_type == 'list')?_c('list',{class:_vm.selected ? _vm.selectedcolor : '',attrs:{"provision":_vm.provision}}):(!_vm.nocontent.includes(_vm.provision.provision_type))?_c('div',{staticClass:"info float-right bg-red-200"},[_vm._v(" "+_vm._s(("(" + (_vm.provision.location) + ") not implemented: " + (_vm.provision.provision_type)))+" ")]):_vm._e()],1),(_vm.provision.provision_type != 'table')?_c('div',{staticClass:"children"},_vm._l((_vm.provision.children),function(child){return _c('ProvisionComponent',{key:child.location,attrs:{"provision":child}})}),1):_vm._e()]),(!_vm.noninteractive.includes(this.provision.provision_type))?_c('div',{staticClass:"rounded-t-xl bg-coolgray-200 absolute -top-7 left-0",class:("" + (_vm.hover ? 'visible' : 'hidden'))},[_c('div',{staticClass:"flex space-x-2 items-center pl-1.5 pr-3"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: this.provision.location + '. ' + this.provision.provision_type }),expression:"{ content: this.provision.location + '. ' + this.provision.provision_type }"}],staticClass:"material-icons-outlined font-bold text-lg text-coolgray-400 hover:text-coolgray-600"},[_vm._v(" more_horiz ")]),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.urlcopy,
            hideOnTargetClick: false
          }),expression:"{\n            content: urlcopy,\n            hideOnTargetClick: false\n          }"}],staticClass:"material-icons-outlined font-bold text-lg text-coolgray-400 hover:text-coolgray-600",on:{"click":_vm.copyURL}},[_vm._v(" link ")]),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: 'Comment on this provision' }),expression:"{ content: 'Comment on this provision' }"}],staticClass:"material-icons-outlined font-bold text-lg text-coolgray-400 hover:text-coolgray-600",on:{"click":function($event){$event.stopPropagation();return _vm.pclicked.apply(null, arguments)}}},[_vm._v(" add_comment ")])])]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }