
















import { Vue } from "vue-property-decorator";
import { Provision } from "@/types";
import { GET_TOC_PROVISIONS_QUERY } from "@/constants/graphql";
import TocItem from "@/components/TocItem.vue";
import { listtotree } from "@/utils";
import Loader from "./Loader.vue";

Vue.component("tocitem", TocItem);
Vue.component("loader", Loader);

export default Vue.extend({
  name: "TocComponent" as string,
  props: ["slug", "loc"],
  data() {
    return {
      provisions: []
    };
  },
  computed: {
    leg(): string {
      return "BILLS-" + this.slug;
    },
    tree(): Provision {
      return listtotree(this.provisions);
    }
  },
  apollo: {
    provisions: {
      query: GET_TOC_PROVISIONS_QUERY,
      variables(): {} {
        return { leg: this.leg };
      }
    }
  }
});
