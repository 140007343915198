





















import { Component, Prop, Vue } from "vue-property-decorator";
import { TextContent } from "@/types";

@Component
export default class TextComponent extends Vue {
  @Prop() content?: TextContent;
  @Prop() indented?: boolean;

  get firstProviso() {
    return this.content?.passages.filter(d => d.tag == "proviso")[0];
  }

  get ind() {
    return this.indented;
  }

  uschref(item: any) {
    const pattern = /\d+ U.S.C. \d+[a-z]*/g;
    if (item.tag != "external-xref") {
      return "";
    } else if (item.text.match(pattern)) {
      const code = item.text.match(pattern)[0].split(" ");
      const title = code[0],
        section = code[2];
      let parenth = item.text.match(/\(\w\)*/g);
      parenth = parenth ? parenth.map(d => d.substring(1, d.length - 1)) : [];
      const subsections = `${
        parenth.length > 0
          ? "#" + parenth[0] + (parenth.length > 1 ? "_" + parenth[1] : "")
          : ""
      }`;
      return `https://www.law.cornell.edu/uscode/text/${title}/${section}${subsections}`;
    }
    return "";
  }
}
