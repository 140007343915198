































































import { Vue } from "vue-property-decorator";

export default Vue.extend({
  name: "About" as string
});
