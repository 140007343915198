




























































































import { Vue } from "vue-property-decorator";

export default Vue.extend({
  name: "Login" as string,
  props: ["phone"],
  data() {
    return {
      form: {
        phone: this.phone ? this.phone : "",
        code: "",
        stayloggedin: true
      },
      smsform: {
        phone: ""
      },
      error: "",
      prevRoute: "/"
    };
  },
  methods: {
    sendSms() {
      this.$store
        .dispatch("sendSms", this.smsform)
        .then(resp => {
          console.log(resp);
          this.error = "";
          this.form.phone = resp.data.phone;
        })
        .catch(err => {
          this.error = err.response ? err.response.data : err;
          if (err.response.status == 429) {
            this.form.phone = this.smsform.phone;
          }
        });
    },
    login() {
      this.$store
        .dispatch("login", this.form)
        .then(() => {
          this.$router.go(-1);
        })
        .catch(err => {
          this.error = err.response.data;
        });
    }
  }
});
