










import { Component, Prop, Vue } from "vue-property-decorator";
import { Provision } from "@/types";

@Component
export default class ListComponent extends Vue {
  @Prop() provision!: Provision;
}
