



































import { Component, Prop, Vue } from "vue-property-decorator";
import { Provision } from "@/types";
import ProvisionComponent from "@/components/ProvisionComponent.vue";
import TextComponent from "@/components/TextComponent.vue";

Vue.component("prov", ProvisionComponent);
Vue.component("textcontent", TextComponent);

@Component
export default class HeaderComponent extends Vue {
  @Prop() provision!: Provision;
  @Prop() endquote?: boolean;
  @Prop() selected!: boolean;

  template1 = [
    "title",
    "subtitle",
    "division",
    "chapter",
    "subchapter",
    "part",
    "subpart"
  ];
}
