


















import { Component, Prop, Vue } from "vue-property-decorator";
import { Provision } from "@/types";

@Component
export default class TableComponent extends Vue {
  @Prop() provision!: Provision;

  get title() {
    for (const child of this.provision.children) {
      if (child.provision_type == "ttitle") {
        return child.text.passages ? child.text.passages[0].text : "";
      }
    }
    return "";
  }

  get tgroup() {
    for (const child of this.provision.children) {
      if (child.provision_type == "tgroup") {
        return child;
      }
    }
    return this.provision;
  }

  get thead() {
    for (const child of this.tgroup.children) {
      if (child.provision_type == "thead") {
        return child;
      }
    }
    return null;
  }

  get tbody() {
    for (const child of this.tgroup.children) {
      if (child.provision_type == "tbody") {
        return child;
      }
    }
    return null;
  }

  text(cell: Provision) {
    let leaf = cell;
    if (cell.children.length > 0) {
      leaf = cell.children[0];
    }
    return leaf.text.passages ? leaf.text.passages[0].text : "";
  }
}
